<template>
  <div>
    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :row-key="rowKey"
              :max-height="$store.state.tableHeight + 30"
              :tree-props="config"
              @selection-change="handleSelectionChange"
              style="width: 100%">
      <!-- :show-overflow-tooltip="item.showToopic" -->
      <el-table-column type="selection"
                       v-if="showSelection"
                       width="55">
      </el-table-column>
      <template v-for="(item,index) in tableColumns">
        <el-table-column :prop="item.prop"
                         :key="index"
                         :sortable="item.sortable"
                         :show-overflow-tooltip="true"
                         v-if="item.isShow && !item.show"
                         :fixed="item.label == '操作'?'right':false"
                         :width="item.width"
                         :align="item.align"
                         :label="item.label">
          <template slot="header">
            <i class="el-icon-setting"
               slot="header"
               v-if="item.label == '操作'"
               @click="showController"></i>
            {{item.label}}
          </template>
          <template slot-scope="{row}">
            <div v-if="item.label == '操作'">
              <!-- {{item.label == '操作'?'fight':''}} -->
              <div v-if="item.type == 'img'">

                <div v-for="(opItem,opIndex) in item.btns"
                     :key="opIndex"
                     class="opertion_image_warp">

                  <div v-if="!opItem.isBtnStatus">

                    <div @click="clickEvent(row,item,opIndex)"
                         v-if="!opItem.isChangeType">
                      <el-tooltip effect="dark"
                                  v-if="opItem.isPd?$parent.getBtnStatus(opItem, row) : true"
                                  :content="opItem.content"
                                  placement="top-start">
                        <img :src="imgs[opItem.type]"
                             class="operyion_img">
                      </el-tooltip>
                    </div>
                    <!-- 若是需要根据值变换图标-->
                    <div @click="clickEvent(row,item,opIndex)"
                         v-if="opItem.isChangeType">
                      <el-tooltip effect="dark"
                                  v-if="opItem.isShowOP"
                                  :content="$parent.getIconContent(opItem,row)"
                                  placement="top-start">
                        <img :src="imgs[$parent.getIconType(opItem,row)]"
                             class="operyion_img">
                      </el-tooltip>
                    </div>
                  </div>
                  <div v-else
                       @click="clickEvent(row,item,opIndex)">
                    <el-button size="mini"
                               round
                               class="mian_but"
                               type="primary"
                               plain>{{opItem.content}}</el-button>
                  </div>
                </div>
              </div>
              <div v-else
                   v-for="(opItem,opIndex) in item.btns"
                   @click="clickEvent(row,item,opIndex)"
                   :key="opIndex"
                   :class="opItem.class">
                <span v-if='opItem.isChangeText'>
                  {{$parent.getText(item.prop,row[item.prop],row)}}
                </span>
                <span v-else>
                  {{opItem.name}}
                </span>

              </div>
            </div>
            <!-- 当不是操作行的时候显示具体内容 -->
            <!-- 将所有的状态都交给主页面做处理 -->
            <div v-else>
              <div v-if="item.isStatus&&item.twoRow">
                <span :class="$parent.getClass(item.prop,row[item.prop],row)"
                      style="margin-bottom:5px">{{$parent.getValue(item.prop,row[item.prop],row)}}</span><br />
                <span :class="$parent.getClass(item.prop1,row[item.prop1],row)">{{$parent.getValue(item.prop1,row[item.prop1],row)}}</span>
              </div>
              <div v-else-if="item.isStatus"
                   :class="$parent.getClass(item.prop,row[item.prop],row)">
                {{$parent.getValue(item.prop,row[item.prop],row)}}
              </div>
              <div v-else-if="item.isImg"
                   class='tableImg'>
                <img :src="row[item.prop]"
                     alt="">

              </div>
              <div v-else
                   class="text_warp">
                {{row[item.prop]}}
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Shuttle ref="ShuttleRef" />
  </div>
</template>

<script>
import Shuttle from './index.vue'
export default {
  data () {
    return {
      tableColumns: [],
      pageName: '',
      imgs: {
        del: require('@/assets/icons/icon-delete@2x.png'),
        add: require('@/assets/login/add.png'),
        edit: require('@/assets/icons/icon-edit@2x.png'),
        look: require('@/assets/icons/icon-look@2x.png'),
        teacher: require('@/assets/classes/teacher.png'),
        student: require('@/assets/classes/student.png'),
        lookStudent: require('@/assets/classes/icon-look.png'),
        editComment: require('@/assets/classes/edit_comment.png'),
        reUpload: require('@/assets/classes/icon-再次上传.png'),
        lookVideo: require('@/assets/classes/icon-预览.png'),
        collect: require('@/assets/icons/icon-tocollect1.png'),
        cancelCollect: require('@/assets/icons/icon-collected1.png'),
        export: require('@/assets/icons/export.png'),
        exam: require('@/assets/icons/exam.png'),
        preview: require('@/assets/icons/icon-priview@2x.png'),
      }
    }
  },
  props: {
    tableData: {
      type: Array,
      default: []
    },
    rowKey: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default () {
        return {}
      }
    },
    showSelection: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Shuttle
  },
  methods: {
    // row传的是当前行，row传的是定义columns的那一行，opindex传的是操作数组的index
    clickEvent (row, item, opIndex) {
      this.$parent[item.btns[opIndex].event](row)
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    setColumns (arr) {
      // 查询浏览器是否存储该页列表项
      if (window.localStorage.getItem(this.pageName)) {
        this.tableColumns = JSON.parse(window.localStorage.getItem(this.pageName))
      } else {
        this.tableColumns = arr.map(item => {
          item.isShow = true
          return item
        })
        // window.localStorage.setItem(this.pageName, JSON.stringify(this.tableColumns))
      }
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    },
    handleSelectionChange (value) {
      this.$emit('setArr', value)
    }

  }
}
</script>

<style lang="scss" scoped>
.opertion_image_warp {
  display: inline-block;
}
.operyion_img {
  width: 24px;
  height: 24px;
  margin-right: 10px;

  cursor: pointer;
}
.el-icon-setting {
  cursor: pointer;
}
::v-deep [data-v-f68e6516] .cell .el-table__expand-icon {
  text-align: left;
  position: absolute;
  margin-left: -50px;
}
.text_warp {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /*禁止换行显示*/
  white-space: nowrap;
}

.opertion_font {
  font-weight: bold;
}

.active {
  color: #5fd3b1;
  font-weight: bold;
  font-size: 15px;
}
.noactive {
  color: #ff7878;
  font-weight: bold;
  font-size: 15px;
}
.mian_but {
  font-size: 12px;
  width: auto !important;
  height: auto !important;
  padding: 6px 8px !important;
  margin-right: 10px;
}
</style>